import logo from './logo.svg';
import './App.css';
import Gh from './img/Messi.jpg'
import Gh1 from './img/Sharukh.jpg'
import Gh2 from './img/Tower.jpg'
import Gh3 from './img/Obama.jpg'
import Gh4 from './img/Flag.jpg'
import {useState,useEffect} from 'react'
import As from './img/as.png'
function App() {

  const [indexMain,setIndex]=useState(0)

  useEffect(()=>{

  },[indexMain])

  const data = [ {
    title: "World’s fastest dribbler ",
    hint1: "Coca - cola lover", hint2: "Car lover ",
   
    // hint3: " Yesh sab dogla pan hai ", 
    option1: "Neymar", option2: 'Cristiano Ronaldo', option3: 'Kylian Mbappé', option4: "Lionel Messi",
     imgurl: Gh,
   },{ title: "Bollywood king",
   hint1: "Cigarette lover", hint2: "Coffee Addict",
   imgurl:Gh1,
  //  hint3: "all the busy guys loves me i m hro vv hh loteegggm bbbb hhhh jjj ", 
   option1: "Akshay Kumar", option2: 'johnny depp', option3: 'Shah Rukh Khan', option4: "John Abraham" },
   {
    title: "Worlds Best Attracting Place",
    hint1: "Best Light Show", hint2: "Most Visited Place in the World",
   
    // hint3: "all the busy guys loves me i m hro vv hh loteegggm bbbb hhhh jjj ", 
    option1: "Mysore Palace", option2: 'Statue of Liberty', option3: 'Taj Mahal', option4: "Eiffel Tower",
     imgurl:Gh2
   },
   {
    title: "1st African-American president ",
    hint1: "44th president of united states", hint2: "won with the largest margin in the history to win u.s. senate",
   
    // hint3: "an Italian high-end luxury fashion house based in Florence, Italy. Its product lines include handbags, ready-to-wear, footwear, ", 
    option1: "Joe Biden", option2: 'George H. W. Bush', option3: 'Barack Obama', option4: "Jimmy Carter",
     imgurl:Gh3
   },
   {
    title: "National Flag of United States ",
    hint1: "50 Stars Indicates 50 States ", hint2: "13 Strips Stand For The Original 13 States",
   
    // hint3: " Corporation is an American multinational chain of coffeehouses and roastery reserves headquartered in Seattle", 
    option1: "New Zealand Flag", option2: 'Australia Flag', option3: 'United States Flag', option4: "South Africa Flag",
     imgurl:Gh4
   },
  
  
  ]
  return (
    <div className="App">
      <div class="dizme_tm_section" id="home">
      { data.length>indexMain ? <div class="dizme_tm_hero">
          <div class="background" style={{
            backgroundImage: `url("https://marketifythemes.net/html/dizme/img/slider/2.jpg")`
          }}> 
       
       
          
          </div>
          <div class="container">
          <div  style={{fontSize:'50px',position:'absolute',top:'10%',fontFamily:'cursive',lineHeight:'10px',width:'100%'}}>   DO YOU KNOW ME ?</div>
            <div class="content">
              <div class="details">
                <div class="hello">
                  
                  <h3 class="orangeText">Hello, I'm </h3>
                </div>
                <div class="name">
                  <h3>{data[indexMain].title}</h3>
                </div>
                <div class="job">
                  <p>A <span class="greenText">{data[indexMain].hint1}</span> and <span class="purpleText">{data[indexMain].hint2}</span></p>
                </div>
                <div class="text">
                  <p> {data[indexMain].hint3}</p>
                </div>
                <div class="button" style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                  <div class="dizme_tm_button" style={{ margin: '5px' }}>
                    <a class="anchor"  style={{ width: "130px" }} onClick={()=>setIndex(indexMain+1)}><span>{data[indexMain].option1}</span></a>
                  </div>
                  <div class="dizme_tm_button" style={{ margin: '5px' }}>
                    <a class="anchor"  style={{ width: "130px" }}  onClick={()=>setIndex(indexMain+1)}><span>{data[indexMain].option2}</span></a>
                  </div>

                </div>
                <div class="button" style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                  <div class="dizme_tm_button" style={{ margin: '5px' }}>
                    <a class="anchor"  style={{ width: "130px" }}  onClick={()=>setIndex(indexMain+1)}><span>{data[indexMain].option3}</span></a>
                  </div>
                  <div class="dizme_tm_button" style={{ margin: '5px' }}>
                    <a class="anchor"  style={{ width: "130px" }}  onClick={()=>setIndex(indexMain+1)}><span>{data[indexMain].option4}</span></a>
                  </div>

                </div>
                <div class="button" style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                  <div class="dizme_tm_button" style={{ margin: '5px' }}>
                    <a class="anchor"  style={{ width: "130px" }}  onClick={()=>setIndex(indexMain+1)}><span>PASS -></span></a>
                  </div>


                </div>
              </div>
              <div class="avatar">
                <div class="image">
                  <img src={data[indexMain].imgurl} alt="" />

                </div>
              </div>
            </div>
          </div>

        </div>:<div class="dizme_tm_hero">
          <div class="background" style={{
            backgroundImage: `url("https://marketifythemes.net/html/dizme/img/slider/2.jpg")`
          }}> 
       
     
        <img style={{marginTop:'2%'}} src="https://i.gifer.com/IXqA.gif" alt="" />
        <div  style={{fontSize:'60px',position:'absolute',top:'44%',fontFamily:'cursive',lineHeight:'10px',width:'100%'}}>  
       YOU PLAYED  VERY WELL 
       
        </div>
        <div className='button' style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}> 
        <div class="dizme_tm_button" style={{ margin: '5px' ,marginTop:'80px' }}>
         <a class="anchor"  style={{ width: "100px"}}  onClick={()=>setIndex(0)}><span>Play Again</span></a></div>
         </div>
     
          </div></div>}
      </div>
    </div>
  );
}

export default App;
